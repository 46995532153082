body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m-card {
  margin: 20px;

}

.m-card-image {
  border-radius: 10px;
  width: 350px;
  height: 425px;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.m-card-info {
  margin-top: 10px;
  min-height: 100px;
}

.m-card-title {
  font-size: 24px;
  margin: 0px;
}

.m-card-subtitle {
  font-size: 16px;
  margin: 0px;
}

.success-message {
  color: #57a773;
  padding: 10px 15px;
}

.error-message {
  color: #ee6352;
  padding: 10px 15px;
}

.m-about-image {
  border-radius: 10px;
  width: 300px;
  height: 325px;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.bottom-padding {
  padding-bottom: 25px;
}